<template>
  <b-overlay :show="isLoading">
    <h1>Send mail to group</h1>
    <b-card class="mb-3">
      <b-form-group label="Group">
        <model-list-select
          v-model="selectedGroup"
          :list="groups"
          option-text="group"
          option-value="group"
          placeholder="Select group"
        ></model-list-select>
      </b-form-group>
      <b-form-group label="Mail template">
        <model-list-select
          v-model="selectedMailTemplate"
          :list="mailTemplates"
          option-text="subject"
          option-value="id"
          placeholder="Choose template"
        ></model-list-select>
      </b-form-group>
      <b-button @click="sendMail">
        Send Mail To Group
      </b-button>
    </b-card>
  </b-overlay>
</template>
<script>
import { ModelListSelect } from "vue-search-select";

export default {
  name: "Notifications",
  components: {
    ModelListSelect,
  },
  data() {
    return {
      groups: [],
      mailTemplates: [],
      selectedGroup: {},
      selectedMailTemplate: null,
      entity: "",
      isLoading: false,
    };
  },
  methods: {
    async fetchGroups() {
      await this.$store.dispatch("Users/fetchGroups");
    },
    async fetchMails() {
      await this.$store.dispatch("Mails/fetchAll");
    },
    async sendMail() {
      this.isLoading = true;
      const payload = {
        group: this.selectedGroup,
        template_id: this.selectedMailTemplate,
      };
      await this.$store.dispatch("Mails/sendMailToGroup", payload);
      this.isLoading = false;
    },
  },
  async created() {
    this.isLoading = true;
    await this.fetchGroups();
    await this.fetchMails();
    this.groups = this.$store.getters["Users/groups"];
    this.mailTemplates = this.$store.getters["Mails/all"]?.data;
    this.isLoading = false;
  },
};
</script>
<style lang="scss"></style>
